<template>
  <div class="page-wrapper">
    
    <div class="data">
      <img src="@/assets/img/logo-coucou-hibou.svg" class="logo" alt="Logo Garderie Coucou Hibou">
      <div class="mobile-img"></div>
      <h1 class="title big">La Garderie Coucou Hibou met à jour son site web</h1>
      <p class="regular-text">La Garderie Coucou Hibou accueillera vos tout-petits dans un milieu de vie naturel, chaleureux et enrichissant. Vous y trouverez un personnel éducateur engagé à contribuer au bien-être ainsi qu’au développement global et harmonieux de vos enfants.  Administrée par une enseignante et gestionnaire d’expérience, et dirigée par une éducatrice chevronnée ayant également fait ses preuves en gestion, cette garderie deviendra un allié précieux des familles dans leur rôle parental. À très bientôt!</p>

      <div class="content-bloc">
        <h2 class="title small">Contactez-nous</h2>
        <a href="mailto:info@garderiepommedamour.com" class="action">
          <img src="@/assets/img/mail-icon.svg" alt="Mail">
          <p class="regular-text">info@coucouhibou.ca</p>
        </a>
        <a href="https://www.google.ca/maps/dir//Mont-Tremblant,+QC/@46.1184402,-74.6662249,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4ccf760dbc945217:0x5040cadae4d54c0!2m2!1d-74.5961852!2d46.1184616!3e0" target="_blank" class="action">
          <img src="@/assets/img/map-icon.svg" alt="Map">
          <p class="regular-text">Mont-Tremblant</p>
        </a>
      </div>

      <div class="content-bloc">
        <h2 class="title small">Carrières</h2>
        <p class="regular-text">Si vous souhaitez vous joindre à notre équipe, veuillez nous faire parvenir votre curriculum vitae par courriel au :</p><br>
        <a href="mailto:info@garderiepommedamour.com" class="action">
          <img src="@/assets/img/mail-icon.svg" alt="Mail">
          <p class="regular-text">info@coucouhibou.ca</p>
        </a>
      </div>

      <div class="content-bloc">
        <h2 class="title small">Inscriptions</h2>
        <p class="regular-text">Pour vous inscrire, veuillez visiter le <a href="https://www.laplace0-5.com/" target="_blank">www.laplace0-5.com</a> ou pour plus d'informations communiquer au (514)270-5055.</p>
      </div>

      <div class="content-bloc">
        <a href="https://garderiepommedamour.com/" target="_blank" class="link-daycare">
          <img src="@/assets/img/logo.png" alt="Logo Garderie Pomme D'amour">
          <p class="regular-text">Visitez le site de la Garderie Pomme D'amour</p>
        </a>
      </div>
    </div>

    <div class="img"></div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'Accueil'
      },
      meta: [
        {
          name: 'description',
          content: 'La Garderie Coucou Hibou accueillera vos tout-petits dans un milieu de vie naturel, chaleureux et enrichissant.',
          id: 'desc'
        },
        { name: 'application-name', content: 'La Garderie Coucou Hibou' },
        { name: 'twitter:title', content: 'La Garderie Coucou Hibou accueillera vos tout-petits dans un milieu de vie naturel, chaleureux et enrichissant.'},
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'La Garderie Coucou Hibou accueillera vos tout-petits dans un milieu de vie naturel, chaleureux et enrichissant.'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'La Garderie Coucou Hibou'},
        { property: 'og:site_name', content: 'La Garderie Coucou Hibou' },
        {
          property: 'og:description',
          content: 'La Garderie Coucou Hibou accueillera vos tout-petits dans un milieu de vie naturel, chaleureux et enrichissant.'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://coucouhibou.ca/img/og-image.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://coucouhibou.ca/img/og-image.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typos.scss';

.page-wrapper {
  display: flex;

  .data {
    width: 600px;
    padding: 50px;

    .logo {
      width:200px;
      margin-bottom: 50px;
    }

    .mobile-img {
      display: none;
    }

    .content-bloc {
      margin-top: 50px;
    }

    .regular-text {
      a {
        color: $vert;
      }
    }

    .action {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      img {
        width: 35px;
        margin-right: 15px;
      }
    }
  }

  .img {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 600px);
    height: 100vh;
    background-image: url("../assets/img/garderie.jpg");
    background-size: cover;
    background-position: center center;
  }

  .link-daycare {
    display: flex;
    align-items: center;

    img {
      width: 100px;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 1080px) {
    .data {
      width: 450px;
    }

    .img {
      width: calc(100% - 450px);
    }
  }

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    .data {
      width: 100%;
      padding: 20px;

      .logo {
        width: 65px;
        margin-bottom: 20px;
      }

      .mobile-img {
        display: block;
        width: 100%;
        height: 300px;
        background-image: url("../assets/img/garderie.jpg");
        background-size: cover;
        background-position: center center;
        margin-bottom: 50px;
      }
    }

    .img {
      display: none;
    }
  }
}
</style>
